import React from "react";
import "./terms.scss";
import tipsAM from "../imgs/forgotTipsAm.svg";

const Terms = () => {
  return (
    <div className="Terms">
      <div className="termsHeader">
        <img src={tipsAM} alt="tips.am" />
      </div>
      <div className="termsTextBox">
        <h4>Ընդհանուր Դրույթներ ու Պայմաններ</h4>
        <h3>
          «Ստեպպերս Օրիոն» Ինտերնետ Ծառայության Միջոցով Վճարումներ Կատարելու <br />
          Ծառայությունների Մատուցման Վերաբերյալ
        </h3>
        <p style={{marginTop:'5px'}}>Ուժի մեջ է մտել՝ 28.03.2022թ.</p>
        <br />
        <br />
        <p>
          Սույն Ընդհանուր դրույթներն ու պայմանները (այսուհետ՝ Պայմաններ)
          տարածվում են բոլոր այն Օգտատերերի վրա, ովքեր օգտվում են «Ստեպպերս
          Օրիոն» սահմանափակ պատասխանատվությամբ ընկերության (այսուհետ՝ Օպերատոր)
          ինտերնետ վճարային համակարգի (այսուհետ՝ Համակարգ) միջոցով վճարումներ
          կատարելու ծառայությունից (այսուհետ՝ Ծառայություն):
        </p>
        <p>
          Պայմանները պարունակում են Օպերատորի կողմից Ծառայության մատուցման
          պայմաններով ծառայություն ստանալու ցանկություն հայտնած ֆիզիկական անձանց
          (այսուհետ՝ Օգտատեր) Ծառայություն մատուցելու վերաբերյալ հրապարակային
          օֆերտայի դրույթներ:
        </p>
        <p>
          Նախքան ծառայությունից օգտվելը Օգտատերը{" "}
          <span style={{ color: "blue", textDecoration: "underline" }}>
            (Ադմինիստրատորը, Թեյավճար Ստացողը և Թեյավճար Վճարողը)
          </span>
          պարտավոր է ծանոթանալ Պայմաններին, որոնց ընթացիկ տարբերակը տեղադրված է{" "}
          <a style={{ color: "blue" }} href="https://www.tips.am">
            https://www.tips.am
          </a>
          կայքում։
        </p>
        <p>
          Պայմանների համաձայն գործողությունների կատարումը (այսուհետ՝ Ակցեպտ)
          Օգտատիրոջ համաձայնության հաստատումն է, հետևաբար Համակարգի
          տեխնոլոգիական հնարավորությունների (գործառույթների, գործիքների)
          օգտագործումը ճանաչվում է որպես Պայմանների ընդունում՝ Ակցեպտ:
        </p>
        <p>
          Պայմանները բխում են ՀՀ քաղաքացիական օրենսգրքի 453-րդ և 454-րդ
          հոդվածների դրույթներից և որպես հրապարակային առաջարկ ուղղված է անձանց
          անորոշ շրջանակին, ովքեր, ըստ իրենց գործողությունների և իրավական
          կարգավիճակի, կարող են լինել Համակարգի Օգտատեր:
        </p>
        <p>
          Օգտատիրոջը Համակարգում գրանցելու և նրա համար հաշիվ ակտիվացնելու պահից
          Պայմանները համարվում են Օգտատիրոջ կողմից ակցեպտավորված (ընդունված,
          հաստատված), և այդ պահից ի վեր սույն Պայմանները դառնում են Օպերատորի և
          Օգտատիրոջ միջև կնքված կատարման համար պարտադիր պայմանագիր (Թեյավճար
          Վճարողի և Թեյավճար ստացողի համար այն ներառում է նաև{" "}
          <span style={{ color: "blue" }}> Միջնորդավճար</span> վճարելու կարգը և
          պայմանները):
        </p>
        <p>
          Ընդունելով Պայմանները՝ Օգտատերը հաստատում է, որ ամբողջությամբ
          ծանոթացել է և ամբողջ ծավալով անվերապահորեն ընդունում է Պայմանները (իսկ
          Թեյավճար Վճարողը և Թեյավճար ստացողը՝ նաև Միջնորդավճար վճարելու կարգը և
          պայմանները):
        </p>
        <p>
          Պայմանները համարվում են Օգտատիրոջ կողմից կնքված և ուժի մեջ են մտնում
          այն պահից, երբ նա կատարում է Պայմաններով իր համար նախատեսված
          գործողությունները, ինչը նշանակում է Պայմանների անվերապահ ընդունում՝
          առանց դրանում նշված որևէ դրույթի բացառության կամ սահմանափակման:
        </p>
        <p>
          Պայմանները կարող են ներկայացվել առցանց կամ Օպերատորի կողմից սահմանված
          այլ եղանակով:
        </p>
        <p>
          Նախքան Համակարգից օգտվելը, անհրաժեշտ է լիովին ծանոթանալ Պայմաններին:
        </p>
        <p>
          Ընդունելով Պայմանները՝ Օգտատերը համաձայնվում է մարքեթինգային
          նպատակների համար Օպերատորին տրամադրած հեռախոսահամարին ստանալ զանգեր
          կամ կարճ հաղորդագրություններ և պատասխանել դրանց: Օգտատերը կարող է
          հրաժարվել զանգ կամ կարճ հաղորդագրություն ստանալուց՝ ուղարկելով «stop»
          հրահանգով կարճ հաղորդագրություն{" "}
          <a href="mailto:info@tips.am"> info@tips.am</a> էլ. փոստին:
        </p>
        <p>1. Պայմաններում օգտագործված հիմնական հասկացությունները.</p>
        <p>
          «Ստեպպերս Օրիոն» ՍՊԸ – սահմանափակ պատասխանատվությամբ ընկերություն
          (այսուհետ՝ Ընկերություն, Օպերատոր), որի մասին տեղեկությունները
          տեղադրված են{" "}
          <a style={{ color: "blue" }} href="https://www.tips.am">
            www.tips.am
          </a>{" "}
          ինտերնետային կայքէջում, և որը Բանկի հետ միասին հնարավորություն է տալիս
          Օգտատիրոջը վճարումներ կատարել «Ստեպպերս Օրիոն» ինտերնետ վճարային
          համակարգի միջոցով՝ սույն Պայմաններով: Ընկերության և Բանկի միջև
          հարաբերությունները կարգավորվում են առանձին պայմանագրով և Պայմանների
          առարկա չեն:
        </p>
        <p>
          «Ստեպպերս Օրիոն» ինտերնետ վճարային համակարգ – Համակարգի տեխնոլոգիական
          հնարավորությունների (գործառույթների, գործիքների) ամբողջությունը,
          ինչպես նաև Համակարգի Օպերատորին սեփականության կամ վարձակալության
          իրավունքով պատկանող սարքավորումների ու ծրագրերի ամբողջությունը, որոնք
          օգտագործվում են Համակարգի Օգտատերերի կողմից ինտերնետ միջավայրում՝
          առցանց, անկանխիկ դրամական փոխանցումներ կատարելու համար և որոնց միջոցով
          Համակարգի Օպերատորն ապահովում է Ծառայության մատուցումը Օգտագործողի
          համար:
        </p>
        <p>
          «Կայք» - Ինտերնետ ցանցում զետեղված Օպերատորի պաշտոնական կայքէջերը՝{" "}
          <a href="https://www.tips.am/"> https://www.tips.am/</a>,
          <a href="clients.tips.am"> clients.tips.am</a> և{" "}
          <a href="restaurants.tips.am"> restaurants.tips.am</a> հասցեներով, որը
          պատկանում և շահագործվում է Ընկերության կողմից, կամ տեղակայված է այլ
          սերվերներում (հոսթինգ), որոնց միջոցով ընկերությունը իրականացնում է իր
          գործունեությունը:
        </p>
        <p>Կայքը պարունակում է տեղեկատվություն Օպերատորի և Պայմանների մասին:</p>
        <p>
          «Ծրագիր» - Օպերատորի կողմից նախատեսված նպատակների համար Համակարգի
          տեխնոլոգիական հնարավորությունների (գործառույթների, գործիքների)
          օգտագործմամբ թեյավճար տալու և թեյավճար ստանալու տեխնիկական
          հնարավորության ապահովումը:
        </p>
        <p>
          «Ծառայություն» - Համակարգի միջոցով վճարումներ կատարելու համար
          Օպերատորի կողմից Օգտատիրոջը մատուցվող ծառայություն:
        </p>
        <p>
          «Կողմեր» - Օպերատոր և Օգտատեր: Համակարգից օգտվող անձինք, ովքեր
          համագործակցում են Պայմաններին համապատասխան՝ Ծրագրի նպատակի իրագործման
          համար:
        </p>
        <p>
          «Օգտատեր» - Այն ֆիզիկական անձը (Ադմինիստրատորը, Թեյավճար ստացողը կամ
          Թեյավճար վճարողը), ով համագործակցում է Օպերատորի հետ՝ Պայմաններին
          համապատասխան Ծրագրի նպատակի իրագործման համար: Օգտատերը Պայմանների
          համաձայն իրավունք է ստանում օգտվելու Համակարգի Ծառայությունից՝
          օգտագործելով Օպերատորի ինտերնետային ռեսուրսը:
        </p>
        <p>Իրավաբանական անձը չի կարող լինել Ծառայության օգտատեր:</p>
        <p>
          «Ադմինիստրատոր» - սույն Պայմաններով ծառայություն մատուցող հաստատության
          (ռեստորանի, սրճարանի, գեղեցկության սրահի և այլն՝ ադմինիստրատոր)
          Օգտատեր, ով հաստատության կողմից մատուցվող ծառայությունների որակը
          բարելավելու նպատակով իր աշխատակցին գրանցում է Համակարգում որպես
          Թեյավճար ստացող, գնահատում է Համակարգի միջոցով Թեյավճար վճարողների
          կողմից թողնված կարծիքները այն հաստատության շրջանակներում, որտեղ նա
          ադմինիստրատոր է:
        </p>
        <p>
          Հաստատության կողմից մատուցվող ծառայությունների որակը բարելավելու
          նպատակով Թեյավճար ստացող իր աշխատակցի աշխատանքի վերաբերյալ Թեյավճար
          վճարողի կողմից կատարված գրառումներին հետևելու համար Սկանավորել/կարդալ
          Օպերատորի կողմից իրեն տրամադրված իր աշխատակցի անձնական QR կոդը, NFC
          պիտակը (tag) կամ դրանց նմանակները՝ առանց Թեյավճար Վճարողի կողմից
          Թեյավճար Ստացողին փոխանցված գումարի չափի և վերջինիս բանկային տվյալների
          հասանելիության:
        </p>
        <p>
          «Թեյավճար ստացող» - Հայաստանի Հանրապետության օրենսդրությանը
          համապատասխան պատշաճ իրավունակություն և գործունակություն ունեցող
          ֆիզիկական անձ, ով Ադմինիստրատորի հետ գտնվում է աշխատանքային
          հարաբերությունների մեջ և գրանցված է Համակարգում, ով օգտագործում է
          Օպերատորի ինտերնետային ծառայության գործառույթը, որպեսզի կարողանա
          Թեյավճար վճարողներից կամավոր դրամական խրախուսումներ ընդունել
          նվիրատվության (թեյավճարի) ձևով, և ում օգտին Թեյավճար վճարողները
          կատարում են վճարումներ:
        </p>
        <p>
          «Թեյավճար վճարող» - Հայաստանի Հանրապետության օրենսդրությանը
          համապատասխան պատշաճ իրավունակություն և գործունակություն ունեցող
          ֆիզիկական անձ, ով, համաձայն Պայմաններով սահմանված կարգի, Ծառայության
          միջոցով կատարում է անկանխիկ դրամական փոխանցումներ (Թեյավճար)՝ Թեյավճար
          ստացողին:
        </p>
        <p>
          Ծառայության չգրանցված օգտագործող, ով իրավունք ունի մատուցված
          ծառայությունների համար Թեյավճար ստացողին վճարել Թեյավճար՝ օգտագործելով
          Ծառայության տեխնիկական հնարավորությունները, ինչպես նաև գնահատել և
          թողնել կարծիք Թեյավճար ստացողների սպասարկման որակի վերաբերյալ:
        </p>
        <p>
          «Թեյավճար» – Թեյավճար ստացողի Ծառայության որակի գնահատման և խրախուսման
          նպատակով Թեյավճար վճարողի կողմից որպես նվիրատվություն Թեյավճար ստացողի
          անձնական հաշվին կատարված 300 (երեք հարյուր) ՀՀ դրամից 10,000 (տասը
          հազար) ՀՀ դրամի միջակայքում անկանխիկ դրամական միջոցների կամավոր և
          անհատույց փոխանցում:
        </p>
        <p>
          «Ակցեպտ» - Օպերատորի կողմից սահմանված կարգով և առաջարկվող պայմաններով
          Համակարգից օգտվելու Օգտատիրոջ համաձայնությունը, որը ենթադրում է
          Օգտագործողի կողմից Պայմանների ամբողջական ու անվերապահ ընդունումը:
        </p>
        <p>
          «Վճարման շրջանակ» - Օպերատորի ինտերնետային էջը, որը պաշտպանված է
          անվտանգության հատուկ միջոցներով, որի միջոցով Թեյավճար ստացողը
          Օպերատորին է տրամադրում Վճարում կատարելու և դրամական միջոցներ
          փոխանցելու համար անհրաժեշտ տվյալները:
        </p>
        <p>
          «Նույնականացուցիչ» - Օպերատորի կողմից Թեյավճար ստացողին հատկացված
          նիշերի շարք, որը թույլ է տալիս վերջինիս նույնականացնել Օպերատորի,
          Բանկի և Ադմինիստրատորի տեղեկատվական համակարգերում:
        </p>
        <p>
          «Գաղտնաբառ» - Ադմինիստրատորի և Թեյավճար ստացողի կողմից ստեղծվող թվերի,
          տառերի և հատուկ նշանների համակցություն, որը Համակարգի կողմից
          օգտագործվում է որպես Օգտատիրոջ նույնականացման միջոց և թույլ է տալիս
          մուտք գործել Համակարգ և օգտվել այնտեղ զետեղված տեղեկատվությունից:
        </p>
        <p>
          «Բջջային հեռախոսահամար» - Օգտատիրոջ կողմից իր բջջային հեռախոսահամարը`
          որպես հաշվի նույնականացուցիչ, օգտագործելու դեպքում Բջջային օպերատորի
          կողմից տրամադրված GSM համար:
        </p>
        <p>
          «Բջջային օպերատոր» - ՀՀ տարածքում գործող բջջային կապ ապահովող
          հեռահաղորդակցության օպերատոր: «Հավելված» - Android կամ iOS օպերացիոն
          հենքերի հիման վրա Օպերատորի կողմից մշակված հավելված, որի միջոցով
          հասանելի է դառնում Հաշվի կառավարումը, Ծառայություններից օգտվելը,
          ինչպես նաև Օգտատիրոջ և Օպերատորի միջև տեղեկատվության (այդ թվում՝
          էլեկտրոնային ծանուցումների) փոխանակումը:
        </p>
        <p>
          «Համակարգի կառավարման վավերապայմաններ» - Ծրագրի նպատակի իրագործման
          համար Օգտատիրոջը նույնականացնող տվյալները (այդ թվում՝ անուն-ազգանունը,
          էլեկտրոնային փոստի հասցեն, Բջջային հեռախոսահամարը), Գաղտնաբառը,
          Նույնականացուցիչը, Հավելվածը և այլն:
        </p>
        <p>
          «Ինտերնետ ծառայություն» - Օպերատորի ինտերնետային ռեսուրսը, որը
          տեղեկատվական համակարգում առկա տեղեկատվության և մտավոր սեփականության
          ամբողջականությունն է, որի մուտքն ապահովվում է ինտերնետային ցանցին
          միացված օգտատերերի տարբեր սարքերից՝ հատուկ ծրագրային ապահովման միջոցով
          ցանցի հասցեով վեբ էջեր (զննարկիչ) դիտելու համար{" "}
          <a style={{ color: "blue" }} href="https://www.tips.am">
            www.tips.am
          </a>{" "}
          (ինչպես նաև կայքի ենթաբաժինների հասցեները): Օպերատորի ինտերնետային
          ծառայությունը ինտերնետ ռեսուրս է, որը նախատեսված է Օգտատերերին
          գրանցելու, իսկ հետագայում Օպերատորի հետ պայմանագիր կնքելու, Օգտատերերի
          գաղտնագրված բանկային տվյալներ պարունակող անձնական QR կոդը և/կամ NFC
          թեգը և/կամ դրանց անալոգը (նմանակը) գեներացնելու հնարավորություն
          ընձեռնելու համար:
        </p>
        <p>
          «Բանկ» - Ֆինանսական գործակալ, Հայաստանի Հանրապետության տարածքում
          գործող ռեզիդենտ բանկ կամ դրամական փոխանցումների իրականացման լիցենզիա
          ունեցող կազմակերպություն, որն Օպերատորի հետ կնքել է պայմանագիր
          Օպերատորի կողմից պատվիրակված գործառույթներ իրականացնելու համար և, որը
          կարող է Օգտատիրոջը տրամադրել Ինտերնետ ծառայության օգտագործմամբ
          վճարումներ կատարելու միջնորդավորված ծառայություններ: Թեյավճար վճարողը
          հնարավորություն է ստանում Ծառայության միջոցով Բանկին հանձնարարել իր
          անունից կատարել Վճարում, իսկ Թեյավճար ստացողը՝ ստանալ Թեյավճար:
        </p>
        <p>
          «Միջնորդավճար» - Օպերատորի և Բանկի միջև կնքված պայմանագրի հիման վրա
          ստեղծված կոմիսիայի համակարգ, մատուցված ծառայությունների համար Թեյավճար
          վճարողի հայեցողությամբ վճարվող Թեյավճար կամ վերջինիս կողմից չվճարելու
          դեպքում Թեյավճար ստացողի կողմից պահվող և Օպերատորի և Բանկի կողմից 6.5
          տոկոսի չափով գանձվող գումար: Միջնորդավճարը զետեղված է Կայքում:
        </p>
        <p>
          «Հաշիվ» - Վճարում կատարելու համար՝ Թեյավճար վճարողի ակտիվացված
          բանկային հաշվեհամարը, ինչպես նաև Թեյավճար ստացողի՝ Համակարգում
          գրանցված և ակտիվացված բանկային հաշվեհամարը:
        </p>
        <br />
        <br />
        <p>2. Առարկան.</p>
        <p>
          <span style={{marginLeft:'15px'}}/>  Պայմանները Համակարգի Ծառայությունից օգտվելու վերաբերյալ Օգտատերերի և
          Օպերատորի միջև կնքվող պայմանագիր է, որը կարգավորում է Օպերատորի և
          Օգտատերերի միջև Համակարգի օգտագործումից ծագած հարաբերությունները:
        </p>
        <p>
        <span style={{marginLeft:'15px'}}/> Պայմանների հիման վրա մատուցվող Ծառայությունը Օպերատորի ինտերնետային
          ռեսուրսի օգտագործմամբ և նրա կողմից մշակված հատուկ ծրագրի (այսուհետ՝
          Ծրագիր) միջոցով թեյավճար տալու և թեյավճար ստանալու տեխնիկական
          հնարավորության ապահովումն է Թեյավճար վճարողից Թեյավճար ստացողին:
        </p>
        <p>
        <span style={{marginLeft:'15px'}}/> Օպերատորը մատուցում է Ծառայություն, իսկ Օգտատերն օգտվում է
          Ծառայությունից՝ համաձայն Պայմանների:
        </p>
        <p>
        <span style={{marginLeft:'15px'}}/>Պայմաններով Օգտատերը հաստատում և երաշխավորում է՝ Ծառայությունն
          օգտագործել բացառապես Թեյավճար վճարողի կողմից դրամական միջոցների
          կամավոր և անվերադարձ փոխանցման նպատակով, որը հնարավորություն է տալիս
          հրահանգներ ուղարկելու կոնտրագենտ-բանկին (այսուհետ՝ Բանկ)՝ որպես
          նվիրատվություն կամավոր և անվերադարձ դրամական փոխանցումներ
          (թեյավճարներ) կատարելու անմիջականորեն Թեյավճար ստացողի (մատուցողների,
          բարմենների և այլ աշխատակիցների, հանրային սննդի կազմակերպությունների,
          ծառայություններ մատուցող և աշխատանք կատարող այլ կազմակերպությունների,
          ինչպես նաև քաղաքացիական իրավունքի պայմանագրերի հիման վրա աշխատանքային
          գործառույթ իրականացնող անձանց) բանկային հաշվին, ում աշխատանքներ
          կատարելու և/կամ ծառայություններ մատուցելու դիմաց ընդունված է դրամական
          պարգևներ (թեյավճարներ) տալը:
        </p>
        <p>
        <span style={{marginLeft:'15px'}}/>Ծրագրի նպատակի իրագործման համար վճարումներ կատարելու հիմնական
          սկզբունքը դրանց կամավորությունն է: Թեյավճար ստացողի կողմից հարկադրանք
          չի թույլատրվում:
        </p>
        <p>
        <span style={{marginLeft:'15px'}}/> Թեյավճար վճարողի և Թեյավճար ստացողի հարաբերությունները ուղղակիորեն
          կապված չեն Բանկի կողմից համապատասխան պայմանագրի հիման վրա Օպերատորին
          տրամադրվող Ծառայության հետ և չեն առաջացնում Բանկի համար որևէ
          պարտավորություն: Բանկը դրամական փոխանցումները կատարում է Վճարման հիման
          վրա՝ Թեյավճար վճարողի ցուցումներին համապատասխան:
        </p>
        <br />
        <br />
        <p>3. Կողմերի իրավունքներն ու պարտավորությունները</p>
        <p>
          3.1. Կողմերն իրավունք ունեն օգտվել Ծառայությունից բացառապես
          Պայմաններով նախատեսված սահմաններում և նպատակներով:
        </p>
        <p>
          3.2. Թեյավճար վճարողը և Թեյավճար ստացողը պարտավորվում են Ծառայությունն
          օգտագործել բացառապես Պայմաններում նշված նպատակի համար՝ չկապված ՀՀ
          օրենսդրությամբ արգելված ձեռնարկատիրական կամ այլ գործունեություն
          իրականացնելու հետ:
        </p>
        <br />
        <br />

        <p>4. Օպերատորն իրավունք ունի.</p>
        <p>
          4.1. Միակողմանի փոփոխություններ և լրացումներ կատարել Պայմաններում և
          Գաղտնիության քաղաքականության մեջ՝ տեղադրելով դրանք Կայքում՝ նոր
          խմբագրությամբ: Փոփոխություններն ու լրացումներն ուժի մեջ են մտնում
          Կայքում հրապարակվելու պահից և տարածվում են Կողմերի
          իրավահարաբերությունների վրա:
        </p>
        <p>
          4.2. Դադարեցնել Ծառայությունների մատուցումը Օգտատիրոջ կողմից դրա
          պայմանները խախտելու դեպքում կամ ՀՀ գործող օրենսդրությամբ նախատեսված
          այլ հիմքերով:
        </p>
        <p>
          4.3. Մերժել որևէ գործարք և կասեցնել Ծառայությունը` սխալ, խախտում կամ
          կեղծիք հայտնաբերելու դեպքում, ինչպես նաև այն դեպքերում, երբ գործարքի
          օրինականությունը կամ չարտոնված օգտագործումից Համակարգի կառավարման
          վավերապայմանների պաշտպանվածությունը կասկած է հարուցում Օպերատորի մոտ:
        </p>
        <p>
          4.4. Արգելափակել Համակարգի կառավարման վավերապայմանները (այդ թվում`
          նույնականացուցիչը) և/կամ պահանջել Օգտատիրոջից փոփոխել դրանք, եթե
          չարտոնված օգտագործումից Համակարգի կառավարման վավերապայմանների
          պաշտպանվածությունը Օպերատորի մոտ կասկած է հարուցում կամ առկա է հավաստի
          փաստաթուղթ, որ դրանք պատկանում են այլ անձի:
        </p>
        <p>
          4.5. Կասեցնել Ծառայությունը մինչև Օգտատիրոջ կողմից Պայմանների 3.3.5
          կետում նշված պահանջի կատարումը, կամ լուծել պայմանագիրը՝ 1 (մեկ) օրվա
          ընթացքում նշված գործառույթը Օգտատիրոջ կողմից չկատարելու դեպքում:
        </p>
        <p>
          4.6. Օգտատիրոջ կողմից այնպիսի գործողություններ թույլ տրվելու դեպքում,
          որոնց արդյունքում կարող է վնաս պատճառվել Օպերատորին, երրորդ անձի կամ
          պետությանը, մերժել Հաշվից վճարում կամ փոխանցում կատարելու Օգտատիրոջ
          կարգադրությունները:
        </p>
        <p>
          4.7. Կասեցնել Ծառայությունը, եթե դա անհրաժեշտ է Օպերատորի, երրորդ
          անձանց կամ պետության իրավունքների և օրինական շահերի պաշտպանության
          համար:
        </p>
        <p>
          4.7. Կասեցնել Ծառայությունը, եթե դա անհրաժեշտ է Օպերատորի, երրորդ
          անձանց կամ պետության իրավունքների և օրինական շահերի պաշտպանության
          համար:
        </p>
        <p>
          4.9. Օրենսդրության փոփոխությունից կամ տեխնիկական անհրաժեշտությունից
          ելնելով՝ փոփոխել նույնացուցիչներին ներկայացվող պահանջները կամ այլ
          պայմանները՝ նախօրոք այդ մասին ծանուցելով Օգտատիրոջը:
        </p>
        <p>
          4.10. Օգտատիրոջ և նրան նույնականացնող փաստաթղթերի տվյալների պարբերական
          մոնիտորինգի ընթացքում հայտնաբերված վավերականության ժամկետը լրացած անձը
          հաստատող փաստաթղթերով Օգտատերերին տեղեկացնել փոփոխված փաստաթղթերը
          ներկայացնելու անհրաժեշտության մասին: Օպերատորի բանավոր կամ գրավոր
          ծանուցումը ստանալու պահից 3 (երեք) աշխատանքային օրվա ընթացքում սույն
          կետով սահմանված պահանջը չկատարելու կամ պատշաճ կարգով չկատարելու
          դեպքում միակողմանիորեն արգելափակել Թեյավճար ստացողի անձնական հաշիվը
          (account) և/կամ Ադմինիստրատորի վահանակը (dashboard):
        </p>
        <p>
          4.11. Ծրագրի նպատակի իրագործման համար ներդնել լրացուցիչ պաշտպանական
          գործիքներ:
        </p>
        <br />
        <br />
        <p>
          5. Օպերատորը պարտավոր է՝ <br />
          5.1. Սահմանել Օգտատիրոջ նույնականացման պատշաճ ուսումնասիրության
          ընթացակարգ՝ համաձայն «Անձնական տվյալների պաշտպանության մասին» և
          «Փողերի լվացման և ահաբեկչության ֆինանսավորման դեմ պայքարի մասին»
          Հայաստանի Հանրապետության օրենքի: <br />
          5.2. Գործադրել անհրաժեշտ միջոցներ առկա տեխնիկական հնարավորությունների
          սահմաններում Ծառայությունից օգտվելու հնարավորությունը և Ծառայության
          պատշաճ որակն ապահովելու համար: <br />
          5.3. Ապահովել անհրաժեշտ տեղեկության հասանելիությունն Օգտատիրոջը
          (Ադմինիստրատորին, Թեյավճար ստացողին, Թեյավճար վճարողին) Պայմանների՝
          իրեն վերաբերելի մասով: <br />
          5.4. Թեյավճար վճարողի կողմից Թեյավճարի առցանց, անկանխիկ դրամական
          փոխանցման տվյալները Համակարգում առկա տվյալների հետ չհամապատասխանելու
          դեպքում ձեռնակել անհրաժեշտ միջոցներ Թեյավճարի և փոխանցված գումարի
          տարբերությունը վերադարձնելու ուղղությամբ: <br />
          5.5. Միջոցներ ձեռնարկել Համակարգի միջոցով փոխանակվող ինֆորմացիայի
          գաղտնիության և երրորդ անձանց չլիազորված մուտքերից, օգտագործումից և/կամ
          բացահայտումից պաշտպանելու ուղղությամբ: <br />
          5.6. Թեյավճար վճարողի խնդրանքով տրամադրել Թեյավճարի առցանց, անկանխիկ
          դրամական փոխանցման վերաբերյալ կնիքով հաստատված տեղեկանք՝ հաստատված
          ստորագրությամբ և կնիքով: <br />
          5.7. Կայքի միջոցով Օգտատիրոջը տեղեկացնել Պայմանների և Գաղտնիության
          քաղաքականության փոփոխությունների, ինչպես նաև բոլոր այն հանգամանքների
          առաջացման, փոփոխման կամ վերացման մասին, որոնք կարող են էական
          նշանակություն ունենալ Ծառայություններից օգտվելու համար: <br />
          5.8. 10 (տասը) աշխատանքային օրվա ընթացքում Օպերատորի ներքին
          ընթացակարգում նշված կարգով (եթե այդպիսին ընդունվել է Օպերատորի կողմից)
          ուսումնասիրել և լուծել Օգտատիրոջ ներկայացրած բողոք-պահանջները: <br />
          5.9. Իրականացնել Համաձայանագրով և Համակարգի Օպերատորի ինտերնետային
          էջով նախատեսված այլ պարտավորություններ: <br />
        </p>
        <br />
        <br />
        <p>
          6. Օգտատերն իրավունք ունի` <br />
          6.1 Օգտվել Ծառայությունից սույն Պայմաններին համապատասխան: <br />
          6.2 Ստանալ տեղեկատվություն առաջարկվող Ծառայության, կիրառվող
          Միջնորդավճարների և այլ պայմանների մասին, <br />
          6.3 Ստանալ տեղեկություններ Պայմանների՝ իրեն վերաբերելի մասով: <br />
        </p>
        <br />
        <br />
        <p>
          7. Օգտատերը պարտավոր է` <br />
          7.1 Օպերատորին տրամադրել իր անձը նույնականացնելու համար անհրաժեշտ ճիշտ
          տվյալներ, իսկ անհրաժեշտության դեպքում՝ Օպերատորի պահանջով ներկայացնել
          վավեր փաստաթղթեր (բնօրինակ, պատճե): <br />
          7.2 Համակարգում իր անձը նույնականացնելու համար հիմք ծառայած՝ անձը
          հաստատող փաստաթղթի փոփոխության դեպքում անհապաղ տեղեկացնել Օպերատորին՝
          տրամադրելով անձը հաստատող փոփոխված փաստաթուղթը (բնօրինակը, պատճեն):
          Սույն կետով սահմանված պարտավորությունը չկատարելու հետևանքով ծագած
          ռիսկերը կրում է Օգտատերը: <br />
          7.3 Ծառայություններից օգտվել անձամբ և բացառապես Պայմանների նպատակների
          համար: Համակարգում առկա իր տվյալները չտրամադրել երրորդ անձանց: <br />
          7.4 Ձեռնպահ մնալ Օպերատորի պաշտոնական կայքը կամ Համակարգի կառավարման
          համար օգտագործվող այլ միջոցները Պայմաններով չնախատեսված որևէ եղանակով
          օգտագործելուց: <br />
          7.5 Անհապաղ հայտնել Օպերատորին Համակարգում հայտնաբերված
          անճշտությունների և արտասովոր հանգամանքների, մասնավորապես, բջջային
          հավելվածի որևէ ծանուցման կամ կարգադրության սխալ լինելու կամ արտացոլված
          չլինելու մասին: <br />
          7.6 Ապահովել Համակարգի կառավարման վավերապայմանների /գաղտնաբառեր,
          PIN-կոդեր/ գաղտնիությունը և պաշտպանվածությունը չարտոնված
          օգտագործումից, <br />
          7.7 Համակարգի կառավարման համար օգտագործվող բջջային հեռախոսահամարի
          փոփոխման, կորստի կամ այլ կերպ տիրապետումից դուրս գալու դեպքում`
          անհապաղ տեղեկացնել Օպերատորին` անձամբ ներկայանալով Օպերատորի
          գրասենյակ:
        </p>
        <br />
        <br />

        <p>
          8. Ծառայությունից օգտվելու կարգը և պայմանները <br />
          8.1. Պայմանների սույն բաժնի դրույթները հանդիսանում են Համակարգի
          շահագործման համար անհրաժեշտ ծրագրային ապահովման և օգտագործման
          վերաբերյալ պայմանները: <br />
          8.2. Օպերատորը Վճարման շրջանակի իրավատերն է:
          <br />
          8.3. Օպերատորը Օգտատիրոջն անվճար փոխանցում է Ծառայությունից օգտվելու
          իրավունքը՝ համաձայն Պայմանների:
          <br />
          8.4. Ծառայություններից օգտվելը չի նշանակում Օգտատիրոջը Ծրագրի նկատմամբ
          որևէ իրավունքի փոխանցում, բացառությամբ Պայմաններում նշված
          իրավունքների:
          <br />
          8.5. Ծրագրի նկատմամբ Օպերատորի իրավունքները պաշտպանված են ՀՀ
          օրենսդրությամբ և Պայմաններով։
          <br />
          8.6. Պայմաններն արտահայտում են Օպերատորի և Օգտատիրոջ բարի կամքը, իր
          բնույթով ոչ առևտրային է և հիմք չէ դրամական հաշվարկների կամ այլ
          նյութական արժեքների փոխանցման համար:
          <br />
          8.7. Օգտատերը համաձայնվում է չօգտագործել Ծրագրի հետ կապված ապրանքային
          նշանները և չփոփոխել, միաձուլել կամ փոխանցել Ծրագրի պատճենները:
          <br />
          8.8. Պայմաններն ուժի մեջ են մտնում Օգտատիրոջ կողմից Պայմաններն
          Ակցեպտավորելու/ընդունելու պահից:
          <br />
          8.9. Ծառայությունից օգտվելու սկիզբը նշանակում է Օգտատիրոջ
          համաձայնությունը Պայմանների բոլոր դրույթներին:
          <br />
          8.10. Ծրագիրը տրամադրվում է Օգտատիրոջը՝ «ինչպես կա», առանց որևէ
          երաշխիքի: Օպերատորը պատասխանատվություն չի կրում Օգտատիրոջ կամ երրորդ
          անձանց վնասների կամ կորուստների համար՝ կապված Ծրագրի օգտագործման հետ՝
          հակառակ Պայմաններով սահմանված կարգին կամ Օպերատորի կողմից տրամադրված
          Ծրագրի գործունեության նկարագրությանը՝ ներառելով, սակայն
          չսահմանափակվելով Օգտատիրոջ ծրագրային ապահովման, սարքավորումների և
          ինտերնետ միացումների համար բացասական հետևանքները:
          <br />
          8.11. Օգտատերն իրավունք չունի օգտագործել Ծրագիրը դրանից ածանցյալ
          ստեղծագործություններ ստեղծելու համար:
          <br />
          8.12. Արգելվում է բացել տեխնոլոգիան և ապակոմպիլացնել (կազմալուծել)
          Ծրագիրը։ Նշված արգելքն ուղղված է Օգտատիրոջ անվտանգության և Ծառայության
          անխափան աշխատանքի ապահովմանը:
        </p>
        <br />
        <br />
        <p>
          9. Ծառայությունից օգտվելու կարգը և պայմանները <br />
          9.1. Ադմինիստրատորի կողմից Ծառայությունից օգտվելու կարգը և պայմանները{" "}
          <br />
          9.1.1. Ծառայությունից օգտվելու համար Ադմինիստրատորին անհրաժեշտ է.
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Գրանցվել Համակարգում,
          </span>{" "}
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Հաստատության կողմից մատուցվող ծառայությունների որակը բարելավելու
            նպատակով Թեյավճար ստացող իր աշխատակցի աշխատանքի վերաբերյալ Թեյավճար
            վճարողի կողմից կատարված գրառումներին հետևելու համար
            Սկանավորել/կարդալ Օպերատորի կողմից իրեն տրամադրված իր աշխատակցի
            անձնական QR կոդը, NFC թեգը կամ դրանց անալոգները՝ առանց Թեյավճար
            Վճարողի կողմից Թեյավճար Ստացողին փոխանցված գումարի չափի և վերջինիս
            բանկային տվյալների հասանելիության:
          </span>
          <br />
          9.1.2. Ադմինիստրատորի կողմից վերը նշված գործողությունների կատարումը
          Պայմանների ընդունումն է: <br />
          9.2. Թեյավճար Վճարողի կողմից Ծառայությունից օգտվելու կարգը և
          պայմանները <br />
          9.2.1. Ծառայությունից օգտվելու համար Թեյավճար վճարողին անհրաժեշտ է.{" "}
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Սկանավորել/կարդալ Օպերատորի կողմից Թեյավճար Ստացողին տրամադրված
            նրա անձնական QR կոդը, NFC թեգը կամ դրանց անալոգները և անցում կատարել
            Վճարման շրջանակին,
          </span>
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Լրացնել Վճարման շրջանակի համապատասխան էկրանային ձևերը՝ Վճարումը
            կատարելու համար անհրաժեշտ տվյալներով,
          </span>
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Կատարելով Պայմանների վերը նշված կետերով նախատեսված
            գործողությունները՝ նշել վճարման ենթակա գումարի չափը և սեղմել
            «Վճարել» կոճակը:
          </span>
          <br />
          9.2.2. Թեյավճար Վճարողի կողմից Պայմանների 9.2.1. կետով նախատեսված
          գործողությունների կատարումը Պայմանների ընդունումն է, ինչպես նաև
          Օպերատորի կայքում հրապարակված Պայմաններով Ծառայության մատուցման
          վերաբերյալ համաձայնության հաստատումը: <br />
          9.2.3. Պայմանների 9.2.1. կետով նախատեսված գործողությունները կատարելով
          Թեյավճար վճարողը, գործելով ողջամտորեն և բարեխղճորեն, հավաստում է, որ
          ինքը պատշաճ կերպով ծանոթացել է Կայքում զետեղված Պայմաններին,
          Գաղտնիության քաղաքականությանը, Միջնորդավճարին և Համակարգի միջոցով
          վճարումներ կատարելու ծառայությունների մատուցման վերաբերյալ
          պայմաններին, հասկանում է դրանց բովանդակությունը և պարտավորվում է
          խստորեն պահպանել դրանց պայմանները: <br />
          9.2.4. Պայմանների 9.2.1. կետով սահմանված կարգով վճարում կատարելու և
          այդ մասին Օպերատորին տեղեկություն տրամադրելու համար Թեյավճար վճարողը
          Համակարգի միջոցով Բանկին է փոխանցում Թեյավճար ստացողի
          նույնականացուցիչի մասին տեղեկություն և հանձնարարում՝ վճարման ենթակա
          գումարի չափով իր Հաշվից գանձել և հօգուտ Թեյավճար ստացողի Հաշվին
          փոխանցել դրամական միջոցներ: <br />
          9.2.5. Թեյավճար վճարողի Հաշվից Թեյավճար ստացողի Հաշվին փոխանցված
          գումարը վերադարձման կամ փոխհատուցման ենթակա չէ: <br /> <br /> <br />
          9.3. Թեյավճար ստացողի կողմից Ծառայությունից օգտվելու կարգը և
          պայմանները <br />
          9.3.1. Ծառայությունից օգտվելու համար Թեյավճար ստացողին անհրաժեշտ է.{" "}
          <br />
          <span style={{ marginLeft: "15px" }}> • Գրանցվել Համակարգում,</span>
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Օպերատորին տրամադրել իր բանկային տվյալները՝ Վճարումը ստանալու
            համար,
          </span>
          <br />
          <span style={{ marginLeft: "15px" }}>
            {" "}
            • Օպերատորից ստանալ անձնական QR կոդ և/կամ NFC թեգ և/կամ դրանց
            համարժեքը, որը պարունակում է Վճարողի բանկային տվյալները գաղտնագրված
            ձևով:
          </span>
          <br />
          9.3.2. Թեյավճար ստացողի կողմից վերը նշված գործողությունների կատարումը
          Պայմանների ընդունումն է: <br />
          9.3.3. Մատուցված ծառայությունների համար Թեյավճար վճարելու նպատակով
          Թեյավճար ստացողը Թեյավճար վճարողին է տրամադրում անձնական QR կոդը և/կամ
          NFC թեգը և/կամ դրանց համարժեքը: <br />
          9.3.4. Վճարում ստացողը վճարում է ՀՀ օրենսդրությամբ սահմանված բոլոր
          հարկերը, տուրքերը և այլ վճարները և պատասխանատվություն է կրում դրանք
          չվճարելու համար: <br /> <br />
          9.3. Կողմերի և Բանկի միջև հարաբերությունները <br />
          9.3.1. Օպերատորի և Բանկի միջև հարաբերությունները կարգավորվում են
          միմյանց հետ կնքված համապատասխան պայմանագրով: <br />
          9.3.2. Թեյավճար վճարողի և Թեյավճար ստացողի հարաբերությունները
          ուղղակիորեն կապված չեն Բանկի կողմից համապատասխան պայմանագրի հիման վրա
          Օպերատորին տրամադրվող Ծառայության հետ և չեն առաջացնում Բանկի համար
          որևէ պարտավորություն: <br />
          9.3.3. Բանկը Օպերատորից ստացված Թեյավճար ստացողի նույնականացման
          տվյալների հիման վրա Թեյավճար վճարողի կողմից տրված հանձնարարության
          հիման վրա ձևավորում է պատվեր և Թեյավճար Վճարողի Հաշվից Թեյավճար
          ստացողի Հաշվին փոխանցում կատարելու վերաբերյալ` համաձայն Պայմաններով
          սահմանված Վճարման պայմանի: <br />
          9.3.4. Բանկը դրամական փոխանցումները կատարում է Վճարման հիման վրա՝
          Թեյավճար վճարողի հանձնարարությանը համապատասխան: <br />
          9.3.5. Բանկը պատասխանատվություն չի կրում Օպերատորի կողմից տրամադրված
          Օգտատիրոջ տվյալների ճշգրտության համար: <br />
          9.3.6. Բանկը, Օպերատորի և իր միջև կնքված պայմանագրի հիման վրա,
          Թեյավճար ստացողից գանձում է Միջնորդավճար՝ Թեյավճար վճարողի կողմից
          վճարված գումարից՝ 6.5 տոկոսի չափով: Ակցեպտավորելով/ընդունելով
          Պայմանները՝ Թեյավճար ստացողը հաստատում է, որ լիովին համաձայն է Բանկի
          կողմից գանձվող միջնորդավճարի չափին: <br />
        </p>
        <br />
        <br />
        <p style={{ textDecoration: "underline" }}>
          10. Գաղտնիություն, տեղեկատվության պահպանում և անվտանգություն
        </p>
        <p>
          10.1 Օգտատիրոջ անձնական տվյալների մշակման, օգտագործման, պաշտպանության,
          երրորդ անձանց փոխանցման հետ կապված հարաբերություններում Կողմերն
          առաջնորդվում են ՀՀ օրենսդրությամբ, Ընկերության{" "}
          <span style={{ color: "blue", textDecoration: "underline" }}>
            {" "}
            Գաղտնիության Քաղաքականությամբ։
          </span>
        </p>
        <br />
        <br />
        <p style={{ textDecoration: "underline" }}>
          11. Կողմերի պատասխանատվությունը
        </p>
        <p>
          Կողմերը պատասխանատվություն են կրում Համակարգում առկա տվյալները երրորդ
          անձանց տրամադրելու և, հետևաբար, երրորդ անձանց կողմից Համակարգում առկա
          գումարների օգտագործամբ կատարված գործարքների համար, բացառությամբ Ծրագրի
          նպատակի իրագործման համար Համակարգի օգտագործման դեպքերի:
        </p>
        <br />
        <br />
        <p>12. Անհաղթահարելի ուժի ազդեցություն (ֆորս-մաժոր)</p>
        <p>
          Կողմերը պատասխանատվություն չեն կրում իրենց պարտավորություններն
          ամբողջությամբ կամ մասամբ չկատարելու կամ դրանց կատարումն ուշացնելու
          դեպքում, եթե դա հետևանք է ջրհեղեղի, հրդեհի, երկրաշարժի, այլ բնական
          աղետի, պատերազմական գործողության, զինված հարձակման, պայթյունի,
          զանգվածային խռովության, գործադուլի, պետական մարմնի կողմից ընդունված
          ակտի, մայրուղային ենթակառուցվածքների խաթարման, էլեկտրասնուցման
          կրճատման կամ թերացած Կողմի վերահսկողությունից դուրս այլ իրադարձության:
        </p>
        <br />
        <br />
        <p>
          13. Ծանուցումներ <br />
          13.1 Պայմաններով այլ բան նախատեսված չլինելու դեպքում Պայմանների
          շրջանակներում բոլոր ծանուցումները կատարվում են էլեկտրոնային եղանակով՝
          բջջային հավելվածի միջոցով: <br />
          13.2 Բացառապես Օգտատիրոջ հարմարության համար Օպերատորը կարող է
          նախատեսված դեպքերում ծանուցումների պատճենները կամ դրանց մասին
          հաղորդագրություններ ուղարկել Օգտատիրոջ կողմից նշված էլեկտրոնային փոստի
          հասցեով և/կամ բջջային հեռախոսահամարով: <br />
          13.3 Տեխնիկական առանձնահատկություններից ելնելով՝ Օպերատորը չի կարող
          երաշխավորել էլեկտրոնային փոստի հասցեով կամ բջջային հեռախոսահամարով
          ուղարկված հաղորդագրությունների ստացումը Օգտատիրոջ կողմից: <br />
          13.4 Որևէ պարագայում Բջջային հավելվածի միջոցով, էլեկտրոնային փոստի
          հասցեով և/կամ բջջային հեռախոսահամարով ծանուցում տալու հնարավորություն
          չունենալու դեպքում, Օգտատերը, ըստ անհրաժեշտության, պետք է կապ հաստատի
          Օպերատորի հետ՝ վերջինիս գրասենյակ այցելելու միջոցով:
        </p>{" "}
        <br />
        <br />
        <p>
          14. Բողոքների և առաջարկությունների ներկայացման, քննարկման և լուծման
          կարգը <br />
          14.1 Բողոքները և առաջարկությունները Օգտատերը ներկայացնում է անձամբ,
          գրավոր եղանակով՝ այցելելով Օպերատորի գրասենյակ, կամ էլեկտրոնային
          փոստով, որոնց Օպերատորը պարտավոր է տալ գրավոր պատասխան: <br />
          14.2 Օպերատորը կարող է ընդունել բողոքները և առաջարկությունները
          քննարկելու ներքին ընթացակարգեր, որը կտեղադրվեն Օպերատորի պաշտոնական
          կայքէջում: Օպերատորը պարտավոր է արձագանքել ստացված բողոքներին և
          առաջարկություններին` իր կողմից ընդունված ընթացակարգերով սահմանված
          կարգով և ողջամիտ ժամկետում՝ պահպանելով օրենսդրությամբ սահմանված
          պահանջները:
        </p>
        <br />
        <br />
        <br />
        <p>
          15. Պայմանագրի պայմանների փոփոխումը <br />
          15.1 Օպերատորը կարող է սեփական հայեցողությամբ միակողմանիորեն փոփոխել
          Պայմանները` այդ մասին առնվազն 3 (երեք) օր առաջ իր պաշտոնական Կայքէջում
          հրապարակային ծանուցմամբ Օգտատիրոջը ծանուցելու միջոցով՝ դրանցում հստակ
          նշելով փոփոխությունների ուժի մեջ մտնելու պահը: <br />
          15.2 Փոփոխությունների հետ համաձայն չլինելու դեպքում` Օգտատերը կարող է
          լուծել Պայմանագիրը` այդ մասին անհապաղ ծանուցելով Օպերատորին: <br />
          15.3 Պայմաններում կատարված փոփոխություններն ուժի մեջ մտնելուց հետո
          Համակարգի միջոցով որևէ գործողություն կատարելու դեպքում
          փոփոխությունները համարվում են Օգտատիրոջ կողմից ընդունված:
        </p>
        <br />
        <br />
        <p>
          16. Վեճերի լուծման կարգը <br />
          Կողմերի միջև առկա վեճերը և տարաձայնությունները լուծվում են
          բանակցությունների միջոցով: Համաձայնություն ձեռք չբերելու դեպքում դրանք
          կարող են լուծվել Հայաստանի Հանրապետության օրենսդրությամբ սահմանված
          կարգով:
        </p>
        <br />
        <br />
        <p>
          17. Պայմանագրի գործողության ժամկետը, այն կասեցնելու և լուծելու կարգը{" "}
          <br />
          17.1 Օգտատիրոջ կողմից Պայմաններն ակցեպտավորելուց հետո Կողմերի միջև
          պայմանագիրը համարվում է անորոշ ժամկետով կնքված և կարող է դադարել
          Կողմերից որևէ մեկի կողմից այն լուծելով: <br />
          17․2 Եթե Օգտատերը գրավոր հայտնում է իր անհամաձայնությունը Պայմանների և
          դրա հետագա փոփոխությունների դրույթների կամ դրա մի մասի վերաբերյալ, ապա
          դա հիմք է տվյալ Օգտատիրոջ մասնակցությունը Համակարգում ժամանակավոր
          կասեցնելու համար՝ մինչև առկա տարաձայնությունների կամ
          անհամաձայնությունների լուծումը: <br />
          17.3 Պայմանագիրը լուծվում/դադարում է. <br />
          17.3.1 Օգտատիրոջ նախաձեռնությամբ՝ Ծառայության օգտագործումը
          դադարեցնելու դեպքում, <br />
          17.3.2 Օպերատորի նախաձեռնությամբ՝ Օգտատիրոջ կողմից Պայմանագրի և/կամ
          սույն Պայմաններով կնքված Պայմանագրի պայմանները խախտելու դեպքում:{" "}
          <br />
          17.4 Օգտատիրոջ նախաձեռնությամբ՝ Պայմանագիրը խզելու մտադրության կամ
          Օպերատորի կողմից Պայմանագրի դադարեցման մասին Օգտատիրոջը ծանուցելու
          դեպքերում Օգտատերը պարտավոր է 1 (մեկ) օրվա ընթացքում դադարեցնել Ծրագրի
          օգտագործումը:
        </p>
        <br />
        <br />
        <p>
          18. Այլ պայմաններ <br />
          18.1 Օգտատերը հավաստում է, որ Պայմանների բոլոր դրույթներն ու
          պայմանները հասկանալի են իր համար, և ինքն ընդունում է դրանք առանց
          վերապահումների և ամբողջությամբ: <br />
          18.2 Պայմանները համարվում են ընդունված Օգտատիրոջ կողմից այն
          Ակցեպտավորելու և Ծառայություններից օգտվելու պահից: <br />
          18.3 Բանկի կողմից Միջնորդավճարը Թեյավճար ստացողից գանձելու դեպքում,
          վերջինս հանձնարարում է Բանկին պահել միջնորդավճար Սակագներում նշված
          չափով՝ Բանկի կողմից հօգուտ Ստացողի փոխանցվելիք Վճարման գումարից:{" "}
          <br />
          18.4 Պայմանագրի Կողմերը սահմանել են Բանկի կողմից Վճարում ստացողի
          նկատմամբ ստանձնած պարտավորությունների կատարման հետևյալ կարգը. <br />
          Վճարում ստացողը Բանկին հանձնարարում է կատարված վճարման շրջանակներում
          իր հետ հաշվարկներ կատարել հետևյալ հաջորդականությամբ.
        </p>
        <p>
          • կատարել դրամական միջոցների փոխանցում հօգուտ Վճարում ստացողի Վճարման
          գումարի չափով՝ հանած. <br />
          • միջնորդավճարի չափը, որի չափը որոշվում է Պայմանների 4.3.1 կետի
          համաձայն և <br />
          • տուրքի չափը, որի չափը որոշվում է Պայմանների 4.4.2 կետի համաձայն:{" "}
          <br />
        </p>
        <h5>2022 Ստեպպերս Օրիոն</h5>
      </div>
    </div>
  );
};

export default Terms;
